import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import "antd/dist/antd.css";

import Routers from "./components/Routers";

import global from "./translations/global.json";
import presenationGlobal from "./translations/presentation/global.json";
import Loading from "./components/presentation/Loading";

const LayoutPresentation = React.lazy(() => import("./components/presentation/Layout"));

function App(props) {
  const language = useSelector((state) => state.language.language);
  const history = useHistory();
  const location = useLocation();
  const combinedTranslation = {
    "en": {
      ...global["en"],
      ...presenationGlobal["en"]
    },
    "sk": {
      ...global["sk"],
      ...presenationGlobal["sk"],
    },
    "cz": {
      ...global["cz"],
      ...presenationGlobal["cz"],
    },
    "hu": {
      ...global["hu"],
      ...presenationGlobal["hu"],
    },
    "pl": {
      ...global["pl"],
      ...presenationGlobal["pl"],
    },
    "ro": {
      ...global["ro"],
      ...presenationGlobal["ro"],
    },
    "de": {
      ...global["de"],
      ...presenationGlobal["de"],
    }
  };

  useEffect(() => {
    if (location.pathname.includes("www"))
      history.push(location.pathname.replace("www.", ""));
  }, []);

  return (
    <IntlProvider
      key={language}
      locale={language}
      messages={combinedTranslation[language]}
    >
      <Suspense fallback={<Loading />}
      >
        <LayoutPresentation>
          <Suspense fallback={<Loading />}>
            <Routers />
          </Suspense>
        </LayoutPresentation>
      </Suspense>
    </IntlProvider>
  )
}

export default App;