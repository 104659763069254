import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { RouterEnum } from "../utils/routerEnum";

import ProtectedRoute from "./ProtectedRoute";

const IncorrectPaymentInformation = React.lazy(() => import("../pages/presentation/IncorrectPaymentInformation"));
const ServiceUnavailable = React.lazy(() => import("../pages/presentation/ServiceUnavailable"));
const NotFoundPresentation = React.lazy(() => import("../pages/presentation/NotFound"));
const ViamoClassic = React.lazy(() => import("../pages/presentation/Viamo/Classic"));
const ViamoMobile = React.lazy(() => import("../pages/presentation/Viamo/Mobile"));

function Routers(props) {
  const presentationRouter = () => {
    return (
      <Switch>
        <Route exact path={RouterEnum.Presentation.IncorrectPaymentInformation.Path} render={() => <IncorrectPaymentInformation />} />
        <Route exact path={RouterEnum.Presentation.ServiceUnavailable.Path} render={() => <ServiceUnavailable />} />
        <Route exact path={RouterEnum.Presentation.ViamoClassic.Path} render={() => <ViamoClassic />} />
        <Route exact path={RouterEnum.Presentation.ViamoMobile.Path} render={() => <ViamoMobile />} />
        <Route render={() => <NotFoundPresentation />} />
      </Switch>
    );
  };

  return presentationRouter();
}

export default Routers;
