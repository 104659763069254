import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware, combineReducers } from "redux";

import reducers from "./reducers";

export default function configureStore() {
  const rootReducer = combineReducers({
    ...reducers
  });

  return createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware())
  );
}
