export const RouterEnum = Object.freeze({
    Presentation: {
        IncorrectPaymentInformation: {
            Path: "/incorrect-payment-information"
        },
        ServiceUnavailable: {
            Path: "/service-unavailable"
        },
        ViamoClassic: {
            Path: "/viamo"
        },
        ViamoMobile: {
            Path: "/viamo/mobile"
        }
    }
});
